import React, {setGlobal} from 'reactn';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Snackbar, SnackbarContent, IconButton, Button, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './withRoot';
import ls from './utils/ls';
import Login from './components/Login'
import Main from './components/Main'

const user = ls();

const styles = theme => ({
  root: {
    textAlign: 'center',
    height: '100vh',
    padding: theme.spacing.unit
  },
  container: {
    height: '100%',
    padding: theme.spacing.unit,
    // border: '1px solid navy',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px lightgrey'
  }
});

class App extends React.Component {
  state = {
    user: null,
  };

  handleClose = () => {
    setGlobal({error: false})
  }

  render() {

    const { classes } = this.props;
    // console.log("GLOBAL STATE IN APP:\n",this.global )

    return (
      <div>
        <div className={classes.root}>

          <Grid container className={classes.container} justify="center" alignItems="stretch">
            <Grid item xs={12}>
              <BrowserRouter>
                <Switch>
                  <Route path="/login/:jwt?" component={Login} />
                  <Route path="/"  component={Main} />
                </Switch>
              </BrowserRouter>
            {this.global.error && this.global.error.response
              ? (
            <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={this.global.error  ? true : false}
                autoHideDuration={6000}
                onClose={this.handleClose}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.global.error.response.data}</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />

              ):null
            }
              
            </Grid>

          </Grid>

        </div>

      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(App))