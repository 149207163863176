import React, { Component } from 'react';
import { Grid, TextField, Button, withStyles, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { FaCheck, FaTimes }  from 'react-icons/fa'

const styles = theme => ({
    root: { flexGrow: 1 },
})

class AppModal extends Component {

    state = {
        open: false,
        item: null
    }

    componentDidMount() {
        this.setState({...this.props})
    }
    
    editModalChangeHandler = name => ev => { //type value in input
        let { item } = this.state
        item[name] = ev.target.value
        this.setState({ item: item })
    }

    render() {
        console.log("====state====",this.state)
        const state = this.state
        const item = this.state.item
        const { classes } = this.props

        return (
            <div>
                {state.open 
                    ? (
                        <Dialog
                        open={state.open}
                        onClose={this.props.onClose()}
                    >
                        <DialogTitle>
                            <Grid container justify="space-between" alignItems="center">
                                <strong> Configurare aplicație {item.name}</strong>
                                <FaTimes onClick={this.props.onClose()} />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs = {3}>
                                <div style={{ padding: '5px' }}>
                                    <TextField
                                        value={item.code}
                                        variant="outlined"
                                        label="cod"
                                        fullWidth={true}
                                        onChange={ this.editModalChangeHandler('code') }
                                    />
                                </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <div style={{ padding: '5px' }}>
                                        <TextField
                                            value={item.name}
                                            variant="outlined"
                                            label="denumire"
                                            fullWidth={true}
                                            onChange={ this.editModalChangeHandler('name') }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ padding: '5px' }}>
                                        <TextField
                                            value={item.url}
                                            variant="outlined"
                                            label="url aplicatie"
                                            fullWidth={true}
                                            onChange={ this.editModalChangeHandler('url') }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ padding: '5px' }}>
                                        <TextField
                                            value={item.desc}
                                            variant="outlined"
                                            label="descriere"
                                            fullWidth={true}
                                            onChange={ this.editModalChangeHandler('desc') }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs = {12} style = {{textAlign:'right'}}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className={classes.button}
                                        color="primary"
                                        onClick={this.props.onClose(state.item)}
                                        >
                                            <FaCheck style={{ fontSize: '1.2em', marginRight: '10px' }} /> CONFIRMĂ
                                    </Button>
                                </Grid>
                            </Grid>

                        </DialogContent>

                    </Dialog>
                    ) :null
                }
                
            </div>
        );
    }
}

export default withStyles(styles)(AppModal);