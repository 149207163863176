import React, { Component, setGlobal } from 'reactn';
import { withRouter } from 'react-router-dom'
import { withStyles, Button, Grid} from '@material-ui/core'
import { FaPuzzlePiece, FaPowerOff, FaUserCheck, FaUsers, FaAddressCard, FaUserCircle} from 'react-icons/fa';
import moment from 'moment'

const styles = theme => ({
    top: {
        backgroundColor: theme.palette.primary.light,
        margin: theme.spacing.unit * 0.5,
        marginLeft: 0,
        color: 'white'
    },
    picture: {
        width: '4em', height: 'auto',
        boxShadow: '0px 0px 10px grey',
        borderRadius:'4em'
    },
    button: {
        marginTop: '3px',
        marginRight: theme.spacing.unit*0.4,
        // border: '1px dashed lightgrey',
        width: '150px'
    }
})

class Header extends Component {

    routeHandler = route => () => {
        this.props.history.push(`/${route}`)
    }

    logoutHandler = () => {
        localStorage.removeItem(this.global.config.NAMESPACE)
        setGlobal(state=>({user:null, apps: null}), ()=>{
            this.props.history.push('/login')
        })
    }

    isAdmin = () => {
        let admin =  this.global.user.apps.filter(elem=> elem.name ==='ADMIN')
        if ( admin && admin.length > 0 ) {
            return true
        } else { 
            return false
        }
    }

    render() {
        const { user, picture, apps } = this.global
        const { classes } = this.props

        return (
            <>
            <Grid container justify="flex-end" className = {classes.top} style = {{backgroundColor:'white'}}>
            { this.isAdmin()
                ?  (
                    <span>
                        <Button variant="text" color="primary" size="medium" onClick = {this.routeHandler('users')} className = {classes.button}>
                            <FaUsers style={{ fontSize: '1.3rem' }} /> 
                            &nbsp;
                            <small style = {{marginTop:'3px'}}> &nbsp;UTILIZATORI</small>
                        </Button>
                       
                        <Button variant="text" color="primary" size="medium" onClick = {this.routeHandler('apps')}  className = {classes.button}>
                            <FaPuzzlePiece style={{ fontSize: '1.3rem' }} /> 
                            &nbsp;
                            <small style = {{marginTop:'3px'}}>&nbsp;APLICAȚII</small>
                        </Button>
                       
                    </span>                                     
                    ): null
            }                            
            <Button variant="text" color="primary" size="medium" onClick = {this.routeHandler('profile')}  className = {classes.button}>
                <FaUserCircle style={{ fontSize: '1.3rem' }} /> 
                &nbsp;&nbsp;
                <small style = {{marginTop:'3px'}}>DATE PERSONALE</small>
            </Button>
            <Button variant="text" color="primary" size="medium" onClick = {this.routeHandler('main')}  className = {classes.button}>
                <FaAddressCard style={{ fontSize: '1.3rem' }} /> 
                &nbsp;&nbsp;
                <small style = {{marginTop:'3px'}}>ABONAMENTE</small>
            </Button>
            
            <Button variant="text" color="primary" size="medium" onClick = {this.logoutHandler}  className = {classes.button}>
                <FaPowerOff style={{ fontSize: '1.3rem' }} /> 
                &nbsp;&nbsp;
                <small style = {{marginTop:'3px'}}>ieșire</small>
            </Button>
        </Grid>
        
        <Grid container justify="flex-start" alignItems="center" className={classes.top}>
            <Grid item xs={10} sm={10} md={10} lg={10} style={{ textAlign:'left', paddingLeft:'10px' }}>
                    <strong> {user.name}</strong>    
                    <br/>     
                    <small>E-mail: <strong> {user.email}</strong></small>
                    <br/>
                    <small>Utilizator activ de la   <strong>{ moment(user.ini_date).format('DD.MM.YYYY')} </strong> </small>
                <br />
            </Grid>
            <Grid item xs = {1} sm={2} md={2} lg ={2} style={{ textAlign: 'right', marginTop:'5px',  paddingRight:'1em'  }}>
            {this.global.user.picture
                ? (
                    <img src={this.global.user.picture} className={classes.picture} alt="profilepic" />
                ) : (
                    <FaUserCircle style = {{fontSize: '3.2rem', color: 'lightblue'}} />
                )
            }
            </Grid>
        </Grid>
        </>
        );
    }
}

export default withRouter(withStyles(styles)(Header));