import React, { Component, setGlobal } from 'reactn';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import { Grid, Paper, withStyles, Typography, Fab, Button } from '@material-ui/core'
import moment from 'moment'
import { } from 'react-icons'
import { FaCaretRight, FaPowerOff, FaUserEdit, FaUserCheck, FaUsers, FaPuzzlePiece } from 'react-icons/fa';
import Header from './Header'
import Subscriptions from './Subscriptions'
import Apps from './Apps/Apps'
import Users from './Users/Users'
import Profile from './Profile/Profile'
import Axios from 'axios'

const styles = theme => {

    return ({

        root: { flexGrow: 1 },

    })
}

class Main extends Component {

    async componentDidMount() {
        // console.log("mounting main")
        const { user, users } = this.global;
        if (!user) {
            console.log("no user")
            this.props.history.push('/login')
        }
        else {

                let usersPromise, appsPromise, error;

                try {
                    usersPromise = await this.getUsers()
                    appsPromise = await this.getApps()
                    
                } catch(err) { error = err }
                finally {
                    // console.log("users promise:", usersPromise)
                    Promise.all([usersPromise, appsPromise]).then( r => {
                        // console.log("all is executed!", r[1])
                        if ( r[0] && r[1] ) {
                            // console.log("setting data in main:", r[0], r[1])
                            this.setGlobal({users: r[0], apps: r[1] })
                        } else {
                            this.setGlobal({error: error })
                        }
                    })
                }
            
        }
        
    }

    getApps = async() => {
        let promise = null;
        try {
            promise = await Axios({
                method: 'POST',
                url: `${this.global.config.API_PATH}/api/v1/find`,
                data: { table: 'apps', data: { where: { id: { not: 0 } } } },
                headers: { 'x-auth-token': this.global.user.token }
            })
        } catch (err) { promise = { error: err } }
        finally {
            // console.log("pr:L", promise)
            if (promise.data) {
                promise.data.map( (e,i) =>  promise.data[i].allowed_roles = JSON.parse(e.allowed_roles))
                return promise.data
            } else {
                return promise.error
            }
        }
    }

    getUsers = async () => {
        const {user} = this.global
        
        let promise;
        try {
            promise = await Axios({
                method: 'POST', 
                url: `${this.global.config.API_PATH}/api/v1/find`,
                data: { table: 'users'},
                headers: { 
                    'x-auth-token': user.token
                }
            })
        } catch(err) {  promise = {error: err}  }
        finally {
            // console.log("get USERS:", promise.data)
            promise.data.map( user => user.apps = JSON.parse(user.apps))
            return promise.data
        }
    }

    isAdmin = (user) => {
        const currentUser = user ? user : this.global.user;
        // console.log("curr user:", currentUser)
        let admin = currentUser.apps.filter(elem => elem.name === 'ADMIN')
        if (admin && admin.length > 0) {
            return true
        } else {
            return false
        }
    }

    render() {
        const { user, apps } = this.global
        const { classes } = this.props
       
        return (
            <>
                {user 
                    ? (
                        <div>
                            <Header />
                            <Switch>
                                <Route path="/main"  component={Subscriptions} />
                                <Route path="/profile/:user?" component={Profile} />
                                {this.isAdmin() ? <Route path="/apps" component={Apps} /> : null}
                                {this.isAdmin() ? <Route path="/users" component={Users} /> : null}

                            </Switch>
                        </div>
                    ) : null
                }

            </>
        )
    }
}

export default withRouter(withStyles(styles)(Main));