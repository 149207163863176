import React, { Component, setGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { withStyles, Grid, Paper, TextField, Typography, Button, withWidth } from '@material-ui/core'
import SocialButton from './SocialButton'
import Axios from 'axios'
import weblogin from '../assets/weblogin.svg';

import { FaFacebook, FaGoogle } from 'react-icons/fa'

const styles = theme => ({
    root: {
        flexGrow:1,
        textAlign: 'center',
        
    },
    container: {
        padding: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 4,
        paddingRight: theme.spacing.unit * 4,
        paddingBottom: theme.spacing.unit * 4,
        textAlign: 'center',
        margin: theme.spacing.unit*2
        
    },
    title: {
        padding: '30px', fontWeight:'900', color: 'navy',
    },
    button: {
        marginTop:'20px', fontSize: '1.5em', fontWeight: 800, 
        // color: 'white', background:'navy',
        '&:hover': {
            backgroundColor: 'navy', color: 'white'
        }
    }
})

class Login extends Component {

    state = {
        username: '',
        password: ''
    }

    componentDidMount() {
        if ( this.global.user ) {
            this.props.history.push('/main')
        }
    }
    
    changeHandler = name => ev => {
        this.setState({
            [name]: ev.target.value
        })

    }

    handleLoginSuccess = async ev => {
        const { _token, _provider } = ev;
        // console.log("SUCCESS EVENT:",_token, _provider)
        let promise;
        try {
            promise = await Axios({
                method: 'POST',
                url: `${this.global.config.API_PATH}/auth/${_provider}`,
                data: {access_token: _token.accessToken }
            })
        } catch(err) { promise = { error: err } }
         finally {
            console.log("PROMISE------:", promise)
            if ( promise.data ) {
                console.log("ok:", promise.data)
                this.setGlobal(
                    {
                        picture: promise.data.picture,
                        user: { token: promise.headers['x-auth-token'] }
                    },
                    ()=>this.jwtHandler(promise.headers['x-auth-token'])
                )
                
            } else { this.setGlobal({error: promise.error})}
        }

    }
    handleLoginFailure = ev => {
        console.log("failure:", ev)
    }

    handleUserPasswordLogin = async () => {
        const { username, password } = this.state;
        let promise;
        try {
            promise = await Axios({
                method: 'POST',
                url: `${this.global.config.API_PATH}/auth/local`,
                data: {username: username, password: password }
            })
        } catch(err) { console.log("error:", err); promise = err }
        finally {
            if ( promise.data ) {
                this.setGlobal(
                    {
                        user: { token: promise.headers['x-auth-token'] }
                    },
                    ()=>this.jwtHandler(promise.headers['x-auth-token'])
                )
                
            } else { 
                console.log("ERROR::::", promise)
                this.setGlobal(prevState=>({error: promise}))
            }
        }
    }

    jwtHandler = async (token) => {
        const { user, picture } = this.global;
        let promise, apps;
        console.log("JWT handler is running!")
        try {
            promise = await  Axios({
                method: 'POST', 
                url: `${this.global.config.API_PATH}/api/v1/current-user`,
                headers: { 'x-auth-token' : token }
            }) 
            
        } catch(err) { promise = {error: err}}
        finally {
           console.log("primise data:",promise.data)
            setGlobal(user => ({user: {...user,...promise.data, token: token}}), ()=>{

                    localStorage.setItem( this.global.config.NAMESPACE, JSON.stringify({
                        user: {...promise.data, token: token, picture: picture}
                    }) )
                    this.props.history.push('/main')        
                    
                })    
                
        }

    }

    render() {
        const { classes } = this.props;
        const { width } = this.props
        return (
            <div className={classes.root} >
                <Grid container justify = "center" alignItems = "center">
                <Grid item xs = {12} sm = {4} lg = {3}>
                <Paper className={classes.container}>
                    
                    <Typography variant = "h5" className = {classes.title}>
                        ACCES CLIENȚI </Typography>
                    <img src = {weblogin} style = {{width: '130px', height: 'auto'}} />
                    <input type='hidden' value='something'/>
                    <Grid container direction="row" justify="space-evenly" alignItems="center" >
                        
                        <Grid item xs = {12}>
                            <TextField
                                value={this.state.username}
                                onChange={this.changeHandler('username')}
                                dense="true"
                                variant="outlined"
                                placeholder="Nume utilizator"
                                label="Nume utilizator"
                                
                                fullWidth
                            />
                        </Grid>
                        &nbsp;
                        <Grid item xs = {12}>
                            <TextField
                                value={this.state.password}
                                type="password"
                                onChange={this.changeHandler('password')}
                                dense="true"
                                variant="outlined"
                                placeholder="Parola"
                                label="Parola"
                                fullWidth
                                
                            />
                        </Grid>
                        <Grid item xs = {12}>
                            <Button
                                size = "large"
                                fullWidth = {false}
                                className = {classes.button}
                                variant = "outlined"
                                onClick = { this.handleUserPasswordLogin}
                            >
                                    AUTENTIFICARE
                            </Button>
                        </Grid>
                        <Grid item xs = {12} >
                            <hr style = {{marginTop:'20px'}}/>
                        </Grid>
                        <Grid item xs = {5} lg = {5}>
                            <SocialButton
                                style = {{padding:'10px', width: '100%', marginTop:'20px'}}
                                variant = "outlined"
                                size = "large"
                                provider='facebook'
                                dense="true"
                                appId= { this.global.config.FACEBOOK_APP_ID }
                                onLoginSuccess={this.handleLoginSuccess}
                                onLoginFailure={this.handleLoginFailure}
                            >
                               
                                <FaFacebook style = {{ fontSize: '3rem', color: 'navy'}}/>
                               
                            </SocialButton>
                        </Grid>
                        &nbsp;
                        <Grid item xs = {5} lg = {5}>
                            <SocialButton
                                style = {{padding:'10px', width: '100%', marginTop:'20px'}}
                                variant = "outlined"
                                size = "large"
                                provider='google'
                                dense="true"
                                appId= { this.global.config.GOOGLE_CLIENT_ID }
                                onLoginSuccess={this.handleLoginSuccess}
                                onLoginFailure={this.handleLoginFailure}
                            >
                                <FaGoogle style = {{ fontSize: '3rem', color: 'red'}}/>
                            </SocialButton>
                        </Grid>

                    </Grid>
                </Paper>
                </Grid>
                </Grid>

            </div>
        );
    }
}

export default withRouter(withWidth()(withStyles(styles)(Login)));