import React, { Component, setGlobal } from 'reactn';
import { withRouter } from 'react-router-dom'
import { withStyles, Grid, Typography, Paper, Menu, MenuItem, Fab, Button } from '@material-ui/core'
import ReactTable from 'react-table'
import { FaPencilAlt, FaTimes, FaPlus } from 'react-icons/fa'
import "react-table/react-table.css";

const style = theme => ({
    root: { flexGrow: 1, height: '75vh', overflow: 'auto', textAlign: 'left', fontSize: '0.8rem' },
    th: { textAlign: 'left', fontWeight: 800 }
})

class Users extends Component {
    state = {
        anchorEl: null,
        user: this.global.user,
        item: null
    };

    contextMenuHandler = (ev, row) => {
        row.apps = JSON.parse(row.apps)
        this.setState({ anchorEl: ev.currentTarget, item: row});
        console.log(ev, row)
    }

    closeMenuHandler = () => { this.setState({ anchorEl: null, item: null }); }

    addHandler = () => { alert("add new!") }

    editUserHander = () => {

        this.props.history.push({
            pathname: '/profile',
            state: {...this.state.item}
        })
    }

    render() {
        const { classes } = this.props
        const { users } = this.global
        const { anchorEl } = this.state
        return (
            <div className={classes.root} >
                <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h6" style={{ fontWeight: 800 }} color="primary">ADMINISTRARE UTILIZATORI</Typography>
                    <Button variant="text" size="small" color="primary" style={{ marginRight: '5px' }} onClick={this.addHandler}>
                        <FaPlus style={{ fontSize: '0.8rem' }} /> &nbsp; ADAUGĂ UTILIZATOR NOU
                    </Button>
                </Grid>
                {users
                    ? (
                        <div>
                            <ReactTable
                                data={users}
                                columns={[
                                    { Header: (<div className={classes.th}>ID</div>), accessor: 'id', width: 50 },
                                    { Header: (<div className={classes.th}>NUME</div>), accessor: 'name', width: 250 },
                                    { Header: (<div className={classes.th}>UTILIZATOR</div>), accessor: 'username', width: 250 },
                                    { Header: (<div className={classes.th}>EMAIL</div>), accessor: 'email', width: 200 },
                                ]}
                                pageText="pagina"
                                filterable={true}
                                ofText="din"
                                rowsText="rânduri"
                                className="-bordered"
                                previousText="anterioară"
                                nextText="următoare"
                                noDataText="informațiile solicitate nu există"
                                defaultPageSize={10}
                                style={{ height: '65vh' }}
                                className="-striped -highlight"
                                defaultFilterMethod={(filter, row) => String(row[filter.id]).toUpperCase().includes(filter.value.toUpperCase())}
                                getTdProps={(state, rowInfo) => {

                                    return {
                                          onClick: ev  => {
                                            ev.preventDefault();
                                            this.setState(
                                                {item: rowInfo && rowInfo.original ? rowInfo.original : null},
                                                () =>  this.editUserHander()   
                                            )
                                            
                                          },
                                        onContextMenu: ev => {
                                            ev.preventDefault();
                                            this.contextMenuHandler(ev, rowInfo && rowInfo.original ? rowInfo.original : null)
                                        }

                                    }
                                }}
                            />

                            <Menu
                                id="context-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.closeMenuHandler}
                            >
                                <MenuItem onClick={this.editUserHander}>
                                    <FaPencilAlt style={{ fontSize: '1rem', marginRight: '5px', color: 'navy' }} /> &nbsp;
                        <Typography variant="caption" style={{ color: 'navy' }}>editare</Typography>
                                </MenuItem>
                                <MenuItem onClick={this.closeMenuHandler}>
                                    <FaTimes style={{ fontSize: '1rem', marginRight: '5px', color: 'red' }} /> &nbsp;
                        <Typography variant="caption" style={{ color: 'red' }}>ștergere</Typography>
                                </MenuItem>

                            </Menu>
                        </div>

                    ) : null

                }

            </div>
        );
    }
}

export default withRouter(withStyles(style)(Users));