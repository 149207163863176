import React, { Component } from 'reactn';
import { withStyles, Grid, Typography, Paper, Menu, MenuItem, Button, Chip, Fab, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core'
import ReactTable from 'react-table'
import "react-table/react-table.css";
import { FaPencilAlt, FaTimes, FaPlus, FaPlusCircle, FaCheck } from 'react-icons/fa';
import RoleModal from './RoleModal'
import AppModal from './AppModal'
import AlertModal from './AlertModal'

const style = theme => ({
    root: { flexGrow: 1, height: '75vh', overflow: 'auto', textAlign: 'left', fontSize: '0.8rem' },
    th: { textAlign: 'left', fontWeight: 800 },
    chip: { marginRight: theme.spacing.unit * 0.3 },
    button: { paddingTop: theme.spacing.unit * 1.9, paddingBottom: theme.spacing.unit * 1.9 }
})

class Apps extends Component {

    state = {
        anchorEl: null,
        item: null,
        role: {
            open: false,
            value: "rolleee",
            target: null
        },
        edit: false,
        alert: {
            open: false,
            text: 'Sigur doriți ștergerea?'
        }
    };

    addAppModalOpen = () => { 
        this.setState({
            item:{
                code:'',
                name: '',
                desc:''
            },
            edit: true 
        })
     }

    contextMenuHandler = (ev, row) => {
        this.setState({ anchorEl: ev.currentTarget, item: row });
    }

    closeMenuHandler = () => { this.setState({ anchorEl: null, item: null }); }

    editAppModalOpenHandler = () => {
        this.setState({ edit: true, anchorEl: null })
    }

    editModalCloseHandler = data => () => {   // actual app-item update TODO
        let {apps} = this.global
        if ( data ) {
            if ( !data.id ) { apps.push({...data, allowed_roles: ["suspended"]})}
            this.setGlobal({apps:apps})
        }
        this.setState({ edit: false })
    }

    removeAppHandler = () => {
        let { item } = this.state
        this.setState({alert:{open:true, text: `Sigur doriți ștergerea aplicației ${item.name}?` }, anchorEl: null})
        
    }

    alertHandler = name => ev => {      //actual app-item removal
        let apps = this.global.apps
        let { item } = this.state
        if ( name ) {
            let foundItem = apps.filter( elem => elem.name === item.name )[0]
            console.log("foundItem;", apps.indexOf(foundItem))
            apps.splice(apps.indexOf(foundItem),1)
            //user clean-up
            
            this.setGlobal({apps:apps})
            console.log("foundItem;", apps.legth)
            this.setState({alert:{open:false}})
        } else {
            this.setState({alert:{open:false}})
        }
    }

    addRoleHandler = (app) => () => {
        this.setState({
            role: { value: '', target: app, open: true }
        })
    }

    roleCloseHandler = value => () => {  //actual role addition to an app
        if (!value) {
            this.setState({ role: { open: false, value: null, target: null } })
        } else {
            const { apps } = this.global
            let currentApp = apps.filter(elem => elem.name === this.state.role.target)
            if (currentApp.length > 0) {
                console.log(currentApp[0])
                currentApp[0]['allowed_roles'].push(value)
                this.setGlobal({ apps: apps })
                this.setState({ role: { open: false, value: null, target: null } })
            }
            console.log(value)
        }
    }

    roleRemoveHandler = (app,role) => () => {
        const { apps } = this.global
        console.log("role remove", app)
       
        let currentApp = apps.filter( elem => elem.name === app.name )
        
        if ( currentApp.length > 0 ) {
            let roleIndex = currentApp[0].allowed_roles.indexOf(role)
            currentApp[0].allowed_roles.splice(roleIndex,1)
           
            // users spliced role clean-up
            this.usersRoleCleanup(app,role)
            this.currentUserRoleCleanup(app,role)

        }
        this.setState({apps:apps})
    }

    usersRoleCleanup = (a,r) => {
        let { users } = this.global
        users.map( 
            (user,iuser) => 
                user.apps.map( 
                    (app, iapp) => {
                        if ( a.name === app.name && r === app.role) {
                            return user.apps.splice(iapp,1)
                        }
                    }
                )
        )
        this.setState({users: users})
    }

    currentUserRoleCleanup = (a,r) => {
        let {user} = this.global    
        user.apps.map( (app,iapp) => { 
            if  (app.name === a.name && app.role === r ) {
                return user.apps.splice(iapp, 1)
            }
        })
        console.log(user)
        this.setGlobal({user:user})
    }
    
    render() {
        const { classes } = this.props
        const { apps } = this.global
        const { anchorEl, role, edit, item, alert } = this.state

        return (
            <div className={classes.root} >
                <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h6" style={{ fontWeight: 800 }} color="primary">ADMINISTRARE APLICAȚII</Typography>
                    <Button variant="text" size="small" color="primary" style={{ marginRight: '5px' }} onClick={this.addAppModalOpen}>
                        <FaPlus style={{ fontSize: '0.8rem' }} /> &nbsp; ADAUGĂ APLICAȚIE NOUĂ
                    </Button>
                </Grid>
                {apps
                    ? (
                        <ReactTable
                            data={apps}
                            columns={[
                                { Header: (<div className={classes.th}>ID</div>), accessor: 'id', width: 50 },
                                { Header: (<div className={classes.th}>COD</div>), accessor: 'code', width: 100 },
                                { Header: (<div className={classes.th}>NUME</div>), accessor: 'name', width: 200 },
                                { Header: (<div className={classes.th}>DESCRIERE</div>), accessor: 'desc', width: 400 },
                                {
                                    Header: (<div className={classes.th}>ROLURI</div>),
                                    accessor: 'allowed_roles',
                                    Cell: row => (
                                        <div>
                                            {row.original.allowed_roles.map((e, i) => (
                                                <Chip
                                                    className={classes.chip}
                                                    variant="outlined" color="primary"
                                                    key={`rl-${i}`}
                                                    label={e}
                                                    onDelete={this.roleRemoveHandler(row.original, e)}
                                                />
                                            ))}

                                        </div>
                                    ),

                                },
                                {
                                    Header: '',
                                    Cell: row => (
                                        <Button size="small" onClick={this.addRoleHandler(row.original.name)}>
                                            <FaPlusCircle style={{ fontSize: '1.2rem', color: 'grey' }} />
                                        </Button>
                                    ),
                                    filterable: false,
                                    width: 50
                                },
                            ]}
                            pageText="pagina"
                            ofText="din"
                            rowsText="rânduri"
                            className="-bordered"
                            previousText="anterioară"
                            nextText="următoare"
                            noDataText="informațiile solicitate nu există"
                            className="-striped -highlight"
                            filterable={true}
                            defaultPageSize={10}
                            style={{ height: '70vh' }}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toUpperCase().includes(filter.value.toUpperCase())}
                            getTdProps={(state, rowInfo) => {

                                return {
                                    //   onClick: (e, handleOriginal) => {
                                    //     // console.log("It was in this row:", rowInfo);
                                    //     props.clicked(rowInfo && rowInfo.original ? rowInfo.original : null)
                                    //   },
                                    onContextMenu: ev => {
                                        ev.preventDefault();
                                        this.contextMenuHandler(ev, rowInfo && rowInfo.original ? rowInfo.original : null)
                                    }

                                }
                            }}
                        />
                    ) : null
                }

                <Menu
                    id="context-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.closeMenuHandler}
                >
                    <MenuItem onClick={this.editAppModalOpenHandler}>
                        <FaPencilAlt style={{ fontSize: '1rem', marginRight: '5px', color: 'navy' }} /> &nbsp;
                        <Typography variant="caption" style={{ color: 'navy' }}>editare</Typography>
                    </MenuItem>
                    <MenuItem onClick={this.removeAppHandler}>
                        <FaTimes style={{ fontSize: '1rem', marginRight: '5px', color: 'red' }} /> &nbsp;
                        <Typography variant="caption" style={{ color: 'red' }}>ștergere</Typography>
                    </MenuItem>

                </Menu>

                { role.open  ?   <RoleModal {...role} onClose = {this.roleCloseHandler} /> : null }
                { edit       ?   <AppModal {...{open: edit, item: item}} onClose = {this.editModalCloseHandler}/> : null }
                { alert.open ?   <AlertModal {...alert} onClose = {this.alertHandler}/> :null }
            </div>
        );
    }
}

export default withStyles(style)(Apps);