import React, { Component } from 'react';
import {  Grid, TextField, Button, withStyles, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { FaTimes, FaPlus } from 'react-icons/fa'

const styles = theme => ({
    root: { flexGrow: 1 },
    button: { paddingTop: theme.spacing.unit * 1.9, paddingBottom: theme.spacing.unit * 1.9 }
})

class RoleModal extends Component {

    state = {
        open: false,
        value: null,
        target: null
    }

    componentDidMount() {
        console.log("monted props" , this.props)
        this.setState({...this.props})
    }

    roleChangeValueHandler = ev => { //typed value in inputs
        this.setState({ value: ev.target.value })
    }

    render() {
        const {classes} = this.props
        const role = this.state
        return (
            
            <div className = { classes.root }>
            {role.open ? 
                 <Dialog
                            open={role.open}
                            onClose={this.props.onClose()}
                        >
                            <DialogTitle>
                                <Grid container justify="space-between" alignItems="center">
                                    <strong>Rol pentru aplicația {role.target}</strong>
                                    <FaTimes onClick={this.props.onClose()} />
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container justify="space-between" alignItems="center">
                                    <div style={{ padding: '5px' }}>
                                        <TextField
                                            value={role.value}
                                            variant="outlined"
                                            label="denumire rol"
                                            fullWidth={true}
                                            onChange={this.roleChangeValueHandler}
                                        />
                                    </div>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className={classes.button}
                                        color="primary"
                                        disabled={role.value.length === 0}
                                        onClick={this.props.onClose(role.value)}
                                    >
                                        <FaPlus style={{ fontSize: '1.2em', marginRight: '10px' }} /> Adaugă
                                </Button>
                                </Grid>

                            </DialogContent>

                        </Dialog>
            
            : null}
            </div>
        );
    }
}

export default withStyles(styles)(RoleModal);