const Config = {
    
    development: {
        APP_NAME: 'ADMIN',
        NAMESPACE: 'auth',
        API_PATH: 'https://api.weblogin.ro',
        GOOGLE_CLIENT_ID: "462523342222-1b9qg12jvj86g4e8jj096mlu723g333s.apps.googleusercontent.com",
        FACEBOOK_APP_ID: "480087575734154",
    },

    production: {
        APP_NAME: 'ADMIN',
        API_PATH: 'https://api.weblogin.ro',
        NAMESPACE: 'auth',
        GOOGLE_CLIENT_ID: "462523342222-1b9qg12jvj86g4e8jj096mlu723g333s.apps.googleusercontent.com",
        FACEBOOK_APP_ID: "480087575734154",
    }
}

export default Config;