import React, { Component } from 'reactn';
import {Grid, Typography, Paper, Fab, withStyles, Chip, } from '@material-ui/core';
import { FaCaretRight } from 'react-icons/fa'
import moment from 'moment'
import "react-table/react-table.css";
import { resolveSoa } from 'dns';

const styles = theme => ({
    root: { flexGrow: 1 },
    paper: {
        padding: theme.spacing.unit,
        margin: theme.spacing.unit,
        '&:hover': {
            backgroundColor: theme.palette.default.light
        }

    },
    top: {
        backgroundColor: theme.palette.primary.light,
        margin: theme.spacing.unit * 0.5,
        color: 'white'
    },
    chip: {
        marginRight: theme.spacing.unit*0.5,
    },
    th: { textAlign:'left', fontWeight: 800 }
})

class Subscriptions extends Component {

    filterApp = name => {
        const { apps } = this.global;
        let result = null;
        let app = apps.filter( elem => elem.name === name )
        if (app.length > 0) { result = app[0] } else { result = null }
        return result
    }

    urlHandler = elem => () => {
        let app = this.filterApp(elem.name)
        if ( app ) {
            console.log("Redirecting to...", app.url, this.global.user)
            document.location = `${app.url}/${this.global.user.token}`
        }
    }

    render() {
        const { classes } = this.props
        const { user, apps } = this.global;
        
        return (
            <div>
                {user && apps
                    ? (
                        <div className={classes.root}>
                            
                            <Grid container justify="space-between" alignItems="flex-start">
                                <Grid item xs={12}>
                                    <Typography variant="h6" color = "primary" style={{ textAlign: 'center', fontWeight: 900 }}> ABONAMENTELE TALE</Typography>
                                    <Paper style={{ textAlign: 'left', height: '70vh', overflow: 'auto' }} >

                                        {this.global.user.apps.map((e, i) => (
                                            <Paper key={`app-${i}`} className={classes.paper} onClick = {this.urlHandler(e)}>
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid item xs={12} md={6} sm={7} lg={8}>
                                                        <strong>
                                                            {e.name}
                                                        </strong>

                                                        <br />
                                                        <small>
                                                            {this.filterApp(e.name) ? this.filterApp(e.name).desc : null}
                                                        </small>
                                                    </Grid>
                                                    <Grid item xs={10} sm={2} md={2} lg={2}>
                                                        <div style={{ textAlign: 'left' }}>
                                                            <small>Expiră la</small>
                                                            &nbsp;
                                                            <strong>
                                                                {e.expiry ? moment(e.expiry).format('DD.MM.YYYY') : 'dată necunoscută'}
                                                            </strong>

                                                        </div>
                                                    </Grid>
                                                    <Fab size="small" color="primary">
                                                        <FaCaretRight style={{ fontSize: '2.5em', paddingLeft: '6px', color: 'white' }} />
                                                    </Fab>

                                                </Grid>
                                            </Paper>

                                        ))}

                                    </Paper>
                                </Grid>

                            </Grid>

                        </div>

                    )
                    : null
                }

            </div>

        );
    }
}

export default withStyles(styles)(Subscriptions);