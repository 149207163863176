import React, { Component } from 'react';
import { withStyles, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'

const styles = theme => ({
    root: { flexGrow: 1 }
})

class AlertModal extends Component {

    state = {
        open: false,
        text: null
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({ ...this.props })
    }

    render() {
        const { classes } = this.props
        const alert = this.state
        console.log("alert state:", alert)

        return (
            <div>
                {alert.open ? (
                    <Dialog
                        open={alert.open}
                        onClose={this.props.onClose(false)}
                    >
                        <DialogContent>
                            {alert.text}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="small" color="primary" onClick={this.props.onClose(true)}>DA</Button>
                            <Button variant="contained" size="small" color="secondary" onClick={this.props.onClose(false)}>NU</Button>
                        </DialogActions>
                    </Dialog>
                ) : null}

            </div>
        );
    }
}

export default withStyles(styles)(AlertModal);