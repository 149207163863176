import Config from '../config'

const config = Config[process.env.NODE_ENV];

const ls = () => {
    let result = null
    let lsobject = localStorage.getItem(config.NAMESPACE)
    if ( lsobject ) {
        result = JSON.parse(lsobject)
    }
    return result

}

export default ls