import React, { Component, setGlobal } from 'reactn';
import ReactDOM from 'react-dom'
import { withStyles, Grid, Typography, Paper, Button, TextField, Chip, Checkbox} from '@material-ui/core'
import moment from 'moment'
import ReactTable from 'react-table'
import { normalize } from 'path';
import { isObject } from 'util';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {FaTimesCircle} from 'react-icons/fa'

const style = theme => ({
    root: { flexGrow: 1, height: '75vh', overflow: 'auto', textAlign: 'left'},
    formContainer: {
        // backgroundColor: theme.palette.default.light,
        padding: theme.spacing.unit * 0.5
    },
    button: {
        margin: `0  6px 0 0`,
        padding: '2px',
        fontSize: '11px',
        width: theme.spacing.unit*10
    },
    th: { textAlign:'left', fontWeight: 800 },
    datePicker: { width: '60%', textAlign: 'center'}
})

class Profile extends Component {

    state = {
        user: null,
        pwDisabled: true
    }

    componentDidMount() {
        console.log("profile is mounted!")
        let newUser;
        if (this.props.location.state) {
            newUser = {...this.props.location.state}
            
        } else {
            
            newUser = {...this.global.user}
            newUser.apps = [...this.global.user.apps]
        }
        if ( !Array.isArray(newUser.apps)) {
            newUser.apps = JSON.parse(newUser.apps)
        }
        let init = {...newUser}
        init.apps = [...newUser.apps]
        this.setState({user: newUser,  userInit:  init })
    }

    changeHandler = name => ev => {
        const { user } =  this.state 
        user[name] = ev.target.value
        this.setState({user: user})

    }

    isAdmin = (user) => {
        let currentUser;
        if ( !user ) {  currentUser =  {...this.state.user}  }
        let admin = currentUser.apps.filter(elem => elem.name === 'ADMIN')
        if (admin && admin.length > 0) {
            return true
        } else {
            return false
        }
    }

    getAllowedRoles = name => {
        const { apps }  = {...this.global}
        let app = apps.filter( elem => elem.name === name )[0]
        return app ? app.allowed_roles : []
    }

    filterApp = name => {
        const { apps } = {...this.global} ;
        let result = null;
        let app = apps.filter( elem => elem.name === name )
        if (app.length > 0) { result = app[0] } else { result = null }
        return result
    }

    roleMatch = (app, role) => {
        const { user } =  this.state
        let hasRole = user.apps.filter( elem => elem.name === app.name && elem.role === role)
        return hasRole.length > 0 ? true : false
    }

    toggleRole = (app,role) => () => {
        
        const { users } = this.global
        let  { user }  = this.state
        
        if ( [...user.apps].filter( item => item.name === app ).length > 0 ) {
            console.log("updating app / role")
            user.apps.map( (e,i) => {
                if ( e.name === app ) { 
                    e.role = role 
                }
            })
        } else {
            console.log("creating app")
            user.apps.push({
                name: app,
                role: role,
                expiry: moment().add(1, 'year').format('YYYY-MM-DD')
            })
        }
        console.log(user.apps)
        this.setState({user: user})
    }

    changeDate = (ev, app) => {
        console.log(ev,app)
        let { users } = {...this.global}
        let  { user }  = this.state
        user.apps.map( (e,i) => {
            if ( e.name === app.name) {
                e.expiry  = moment(ev).format('YYYY-MM-DD') 
            } 
        })
        this.setState({user: user})
    }

    getElem = (app,elem) => {
        let result
        const { user } = this.state
        let hasElem = user.apps.filter( item =>  item.name === app)
        if ( hasElem.length > 0  ) {
            if ( elem === 'expiry') {
                result = hasElem[0][elem] ? moment(hasElem[0][elem]).toDate() : null
            } else {
                result = hasElem[0][elem]
            } 
        }        
        return result ? result : null
    }  

    removeApp = app => () => {
        
        let {user} = this.state
        let selApp = user.apps.filter( elem => elem.name === app.name )
        console.log("removing app....", selApp, user.apps.length)
        if ( selApp.length > 0 ) {
            user.apps.splice(user.apps.indexOf(selApp[0]),1)
            this.setState({user:user}, ()=> console.log("removing app....", selApp, user.apps.length))
        }

    }

    confirmHandler = () => {
        console.log(
            "user:",
            this.state.user,
            "\ninit:",
            this.state.userInit)
    }

    passwordHandler = ev => {
        let elem = ev.currentTarget
        
    }

    render() {
        const {classes} = this.props
        const { apps } = this.global
        const { user } =  this.state    
        return (
            <div className = {classes.root}> 
            { user ? (<>
                <Grid container justify = "space-between" alignItems ="center" > 
                    <Typography variant = "h6" style = {{fontWeight: 800}} color = "primary">EDITARE PROFIL</Typography>
                    <Button variant = "contained" size ="small" color = "secondary" onClick = {this.confirmHandler}>CONFIRMĂ</Button>
                </Grid>
          
                <Grid container justify = "flex-start" alignItems = "center" >
                    <Grid item xs = {12} sm = {2} md = {2} lg = {2} className = {classes.formContainer}>
                        <TextField 
                            style = {{backgroundColor: 'white'}}
                            value = {user.name} 
                            onChange = { this.changeHandler('name')}
                            variant = "outlined"
                            dense = "true"
                            fullWidth
                            label = "numele dvs"
                        />
                    </Grid>
                    <Grid item xs = {12} sm = {2} md = {2} lg = {2} className = {classes.formContainer}>
                        <TextField 
                            style = {{backgroundColor: 'white'}}
                            value = {user.email} 
                            onChange = { this.changeHandler('email')}
                            variant = "outlined"
                            dense = "true"
                            fullWidth
                            label = "adresa dvs de email"
                        />
                    </Grid>
                    <Grid item xs = {12} sm = {2} md = {2} lg = {2} className = {classes.formContainer}>
                        <TextField 
                            style = {{backgroundColor: 'white'}}
                            value = {user.username} 
                            onChange = { this.changeHandler('username')}
                            variant = "outlined"
                            dense = "true"
                            fullWidth
                            label = "nume utilizator"
                        />
                    </Grid>
                    <Grid item xs = {12} sm = {2} md = {2} lg = {2} className = {classes.formContainer} >
                        <TextField 
                            style = {{backgroundColor: 'white'}}
                            defaultValue = {user.password} 
                            onClick = {this.passwordHandler}
                            placeholder = "Schimba parola"
                            onChange = { this.changeHandler('password')}
                            variant = "outlined"
                            type = "password"
                            dense = "true"
                            fullWidth
                            label = "parola"
                            
                        />
                    </Grid>
                    
                </Grid>
                <hr/>
                <Grid container justify = "space-between" alignItems = "center">
                    <Typography variant = "h6" color = "primary" style = {{textAlign: 'center', fontWeight:800}}>ABONAMENTE</Typography>

                </Grid>
                <div style = {{fontSize: '0.8rem'}}> 
                        <ReactTable 
                            data = {apps}
                            columns = {[
                                
                                {Header: (<div className = {classes.th}>APLICAȚIE</div>), accessor:'name', width: 200},
                                {
                                    Header: (<div className = {classes.th}>ROL</div>),
                                    accessor:'role', 
                                    filterable: false,
                                    Cell: row => this.getElem(row.original.name, 'role'),
                                    width: 100},
                                // {
                                //     Header: (<div className = {classes.th}>EXPIRARE</div>),
                                //     accessor:'expiry',
                                //     Cell: row => (this.getElem(row.original.name, 'expiry')),
                                //     width: 100
                                // },
                                {
                                    Header: (<div className = {classes.th}>VALABILITATE</div>), 
                                    accessor: 'apps.expiry',
                                    id: 'exp',
                                    filterable: false,
                                    Cell: row => (
                                        <div style = {{textAlign:'center'}}>
                                            {this.getElem(row.original.name, 'expiry')
                                                ?(
                                                    <DatePicker
                                                    className = {classes.datePicker}
                                                    onChange = {ev => this.changeDate(ev, row.original)}
                                                    selected = {this.getElem(row.original.name, 'expiry')}
                                                    dateFormat="dd.MM.YYYY"
                                                    defaultValue={null}
                                                />
                                                ):null
                                            }
                                    </div> ),
                                    width: 150
                                },
                                {
                                    Header: (<div className = {classes.th}>PERMISIUNI</div>), 
                                    accessor: 'roles',
                                    filterable: false,
                                    Cell: row => (<div> {this.getAllowedRoles(row.original.name).map( (e,i) => (
                                        <span  key = {`role-${i}`} >
                                           
                                        <Button 
                                            className = {classes.button} 
                                            size = "small"
                                            variant = {this.roleMatch(row.original, e)  ? "contained" : "outlined"}
                                            color = {this.roleMatch(row.original, e)  ? "primary" : "secondary"}
                                            onClick = { this.toggleRole(row.original.name, e) }
                                        >{e}</Button>
                                        </span>
                                    ),
                                    
                                    )} </div>),
                                   
                                },
                                {
                                    Header: (<div className = {classes.th}><FaTimesCircle style = {{color: 'lightgrey'}}/></div>), 
                                    Cell: row => <Button onClick  = {this.removeApp(row.original)}><FaTimesCircle style = {{color: 'darkblue', fontSize:'1.1em'}}/></Button>,
                                    filterable: false,
                                    width: 50

                                }

                            ]}
                            showPagination = {true}
                            pageText = "pagina"
                            filterable = {true}
                            ofText = "din"
                            rowsText = "rânduri"
                            className="-bordered"
                            previousText = "anterioară"
                            nextText = "următoare"
                            noDataText = "informațiile solicitate nu există"
                            defaultPageSize={10}
                            style = {this.props.style ? this.props.style : {height: '54.5vh'}}
                            className="-striped -highlight"
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toUpperCase().includes(filter.value.toUpperCase())}
                        />
                        </div>
                        </>):null}         
            </div>
        );
    }
}

export default withStyles(style)(Profile);