import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Config from './config'
import ls from './utils/ls'

const config = Config[process.env.NODE_ENV]
const storage = ls()

setGlobal({
    config: config,
    user: storage ? storage.user: null,
    users: null,
    picture: null,
    apps: storage ? storage.apps : null,
    error: false
})
// console.log("*************", storage)
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
